<template>
  <div class="managers">
    <default-user-page v-bind="config"/>
  </div>
</template>

<script>
import {
  getManagers,
  getManager,
  postManager, putManager, removeManager,
} from "@/services/managers";
import DefaultUserPage from "@/components/default_user_page";

export default {
  components: {DefaultUserPage},
  data() {
    return {
      config: {
        label: 'manager',
        confirmable: true,
        fetchRecords: getManagers,
        fetchRecord: getManager,
        postRecord: postManager,
        putRecord: putManager,
        removeRecord: removeManager,
      }
    }
  }
}
</script>